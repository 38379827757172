// import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";
import "./faq.css";

export default function Frequently() {
  const data = {
    rows: [
      {
        title: "How can I get a loan?",
        content: `Create an account on Rex, click on loans; select how much you need and accept the loan offer.`,
      },
      {
        title: "What is the interest rate for loans?",
        content:
          "The interest rate is calculated based on the tenure and amount selected. Our loans are competitive.",
      },
      {
        title: "How does taking a loan improve my credit score?",
        content: `Taking a loan and paying on time improves your credit rating, and this helps you access higher amounts subsequently.`,
      },
    ],
  };

  const styles = {
    // bgColor: 'white',
    titleTextColor: "blue",
    rowTitleColor: "#7399EF",
    rowContentColor: "#121212",
    arrowColor: "#121212",
    rowTitleTextSize: "23px",
    rowContentPaddingTop: "20px",
    rowContentTextSize: "18px",
    rowContentPaddingBottom: "20px",
  };

  const config = {
    // animate: true,
    // arrowIcon: "V",
    tabFocus: true,
    openOnload: 1,
    expandIcon: "+",
    collapseIcon: "-",
  };
  return (
    <div className="frequently">
      <div className="width">
        <div>
          <div className="answer">
            <p>Frequently asked questions</p>
            <p>Let's answer some of your questions</p>
          </div>
          <Faq data={data} styles={styles} config={config} />
        </div>
      </div>
    </div>
  );
}
