import "./lock.css";
import afric from "../../image/afric.png";

export default function Lock() {
  return (
    <div className="lock">
      <div className="width">
        <div className="inpic">
          <div className="in">
            <p>
              <span>Buy at tomorrow’s higher price </span> <br /> Lock in
              today’s price, reduce financial strain
            </p>
            <p className="eighteen">
              Buy your favorite gadgets & household items, and pay later at 0%
              Interest. With just 50% down payment and balance spread over a few
              months, you can get your choice items and reduce the strain on
              your finances.
            </p>
          </div>
          <div className="in">
            <div className="image">
              <img src={afric} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
