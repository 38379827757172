import React from "react";
import "./yourmoney.css";
import phone from "../../image/Group 33652.png";
import icon from "../../image/Group 335761.svg";
import icon2 from "../../image/Group 335762.svg";

export default function Yourmoney() {
  return (
    <div className="yourmoney">
      <div className="width">
        <div className="put">
          <p>Savings</p>
          <p>
            Put your money to work with a <br />
            high yielding savings account
          </p>
          <p className="twentyone">
            Our products help you to cultivate good savings habits. <br />
            Create goals, save for your future, and earn great returns on Rex.{" "}
            <br />
            That Dubai vacation or Canadian Master’s program will not save for
            itself!
          </p>
        </div>

        <div className="phonetext">
          <div className="phone">
            <img src={phone} alt="" />
          </div>
          <div className="text">
            <div className="auto">
              <div>
                <img src={icon} alt="" />
              </div>

              <div className="talk">
                <p>Automate your savings</p>
                <p className="twentyone">
                  Build an exceptional portfolio by enabling our autosave
                  feature. Now you can save any amount you want at a frequency
                  of your choice.
                </p>
              </div>
            </div>
            <div className="auto">
              <div>
                <img src={icon2} alt="" />
              </div>

              <div className="talk">
                <p>Earn high interest </p>
                <p className="twentyone">
                  Protect your money by putting it to work to earn high interest
                  rates. Create a target savings plan on Rex, and take another
                  step towards achieving your financial goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
