import "./fixed.css";
import Header from "../../components/header/Header";
import app from "../../image/Group 33625.svg";
import app2 from "../../image/Group 33626.svg";
import phone from "../../image/phonee.png";
import icon from "../../image/Group 33576 (3).png";
import icon2 from "../../image/Group 33576 (4).png";
import icon3 from "../../image/Group 33576 (5).png";
// import pic from "../../image/Group 33689.png";
import phone1 from "../../image/iPhone (1).png";
import Faq from "react-faq-component";
import one from "../../image/new/Group 33689.png";
import two from "../../image/new/two.png";
import three from "../../image/new/three.png";
import four from "../../image/new/four.png";
import { useState } from "react";

export default function Fixed() {
  const data = {
    rows: [
      {
        title: "How can I invest on Rex?",
        content: `Create an account on Rex, click on  Investment; specify how much you want to invest and for how long. Fund your account to complete your investment plan.`,
      },
      {
        title: "What’s the interest rate for investments on Rex?",
        content:
          "The interest rate is calculated based on the tenure of your investment. Our interest rates are competitive. Earn up to 18% per annum.",
      },
      {
        title: "How does Rex help me achieve my financial goals? ",
        content: `Investing through Rex helps you develop the financial discipline needed to achieve your goals. Start investing towards your 
        short and long term goals today. `,
      },
    ],
  };

  const styles = {
    // bgColor: 'white',
    titleTextColor: "blue",
    rowTitleColor: "#7399EF",
    rowContentColor: "#121212",
    arrowColor: "#121212",
    rowTitleTextSize: "23px",
    rowContentPaddingTop: "20px",
    rowContentTextSize: "18px",
    rowContentPaddingBottom: "20px",
  };

  const config = {
    // animate: true,
    // arrowIcon: "V",
    tabFocus: true,
    openOnload: 1,
    expandIcon: "+",
    collapseIcon: "-",
  };

  const [no, setNo] = useState(0);
  const [image, setimage] = useState(one);

  // console.log(slider);

  const details = [
    {
      first: "Create an account on Rex",
      second: "Add your email and other details, and log in to the app",
    },
    {
      first: "Create an Investment plan",
      second: "Select the amount you want to invest, and for how long",
    },
    {
      first: "Review your investment plan",
      second:
        "Review your investment plan, see your interest rate, maturity date and confirm this.",
    },
    {
      first: "Fund your plan and start investing",
      second:
        "Kickstart your investment plan by funding via: your Rex wallet, bank transfer or  card",
    },
  ];

  const dosomething = (id) => {
    setNo(id);

    if (id === 0) {
      setimage(one);
    } else if (id === 1) {
      setimage(two);
    } else if (id === 2) {
      setimage(three);
    } else {
      setimage(four);
    }
  };

  return (
    <div className="fixed">
      <div className="savings">
        <div className="credit">
          <Header dark />
          <div className="home">
            <div className="body width">
              <div className="talk">
                <div className="cspi">
                  <span>Investment</span>
                </div>
                <p>
                  Get{" "}
                  <span>
                    {" "}
                    more from your <br /> money
                  </span>{" "}
                  with Rex
                </p>
                <p className="twentyone">
                  Get greater returns when you invest on Rex. Achieve your
                  financial <br /> dreams by putting your money to work. The
                  best time to start investing <br /> was a couple of years ago,
                  the next best time is right now.
                </p>
                <p>Get started on rex</p>

                <div className="button">
                  <img src={app2} alt="" />
                  <img src={app} alt="" />
                </div>
              </div>
              <div className="img">
                <img src={phone1} alt="" />
              </div>
            </div>
          </div>

          <div className="yourmoney">
            <div className="width">
              <div className="put">
                <p></p>
                <p></p>
                <p className="twentyone"></p>
              </div>

              <div className="phonetext">
                <div className="phone">
                  <img src={phone} alt="" />
                </div>
                <div className="text">
                  <div className="save">
                    <p className="fifty">Put your money to work</p>
                    <p className="twentyone">
                      Our investment product helps protect your money from
                      inflation and currency devaluation
                    </p>
                  </div>
                  <div className="auto">
                    <div>
                      <img src={icon} alt="" />
                    </div>

                    <div className="talk">
                      <p>Invest for the future</p>
                      <p className="twentyone">
                        Our products help you create the future you desire for
                        you and your loved ones. Whatever your goals are, you
                        can start investing for them.
                      </p>
                    </div>
                  </div>
                  <div className="auto">
                    <div>
                      <img src={icon2} alt="" />
                    </div>

                    <div className="talk">
                      <p>Invest with a purpose</p>
                      <p className="twentyone">
                        Whatever your life goals are, you can start investing
                        some money towards these targets on Rex. Financial
                        independence is a lifetime journey, with our product you
                        can kick start the journey with a step today.
                      </p>
                    </div>
                  </div>
                  <div className="auto">
                    <div>
                      <img src={icon3} alt="" />
                    </div>

                    <div className="talk">
                      <p>Roll over your interest</p>
                      <p className="twentyone">
                        Leverage on compounding interest to increase the value
                        of your portfolio. With this feature, upon your plan’s
                        maturity, you can decide to roll over your interest and
                        principal to earn more in the short and long term.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="width">
            <div className="service">
              <p></p>
              <p> Start Investing on Rex in just a few steps</p>
              <p>Create the future you want, one investment plan at a time!</p>
            </div>
          </div>

          <div className="create">
            <div className="width">
              <div className="imagecreate">
                <div className="image">
                  <img src={image} alt="" />
                </div>
                <div className="create">
                  {details.map((detail, id) => (
                    <div
                      key={id}
                      onClick={() => dosomething(id)}
                      className={no === id ? "account active" : "account"}
                    >
                      <p>{detail.first}</p>
                      <p>{detail.second}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* faq */}
          <div className="frequently">
            <div className="width">
              <div>
                <div className="answer">
                  <p>Frequently asked questions</p>
                  <p>Let's answer some of your questions</p>
                </div>
                <Faq data={data} styles={styles} config={config} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
