import Header from "../header/Header";
import { Link } from "react-scroll";
import { useState, useEffect } from "react";
import icon2 from "../../image/Arrow - Down 2.png";
import "./terms.css";

 
export default function Privacy() {
  const [show, setshow] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    const href = window.location.href.substring(
      window.location.href.lastIndexOf('#') + 1,
    );
    if (window.location.href.lastIndexOf('#') > 0) {
      document.getElementById(href)?.scrollIntoView();
    }
   });


  const handleclick = () => {
    setshow(!show);
  };

  const comot = () => {
    setshow(false);
  };

  return (
    <div className="privacy">
      <div className="terms">
        <Header dark />

        <div className="ourterms">
          <div className="width">
            <div className="top">
              <p>Privacy Policy</p>
              <p>Last Updated: 30th June 2023</p>
            </div>

            <div className="input" onClick={handleclick}>
              <input
                type="text"
                placeholder="Select a section of our privacy policy"
              />
              <img src={icon2} alt="" />

              <div className={show ? "drop" : "none"}>
                <p>
                  <Link
                    to="twt"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    onClick={comot}
                  >
                    To whom this Privacy Policy applies and what it covers
                  </Link>
                </p>
                <p>
                  <Link
                    to="owa"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    onClick={comot}
                  >
                    Other Website/Apps
                  </Link>
                </p>
                <p>
                  <Link
                    to="con"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    onClick={comot}
                  >
                    Consent
                  </Link>
                </p>
                <p>
                  <Link
                    to="wiw"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    onClick={comot}
                  >
                    What information we collect
                  </Link>
                </p>
                <p>
                  <Link
                    to="uop"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    onClick={comot}
                  >
                    Use of personal information to provide products and services
                  </Link>
                </p>
                <p>
                  <Link
                    to="upi"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    onClick={comot}
                  >
                    Use of personal information collected via our Website/App
                  </Link>
                </p>

                <p>
                  <Link
                    to="tlg"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    onClick={comot}
                  >
                    The legal grounds we rely on for processing personal
                    information
                  </Link>
                </p>
                <p>
                  <Link
                    to="hlw"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    onClick={comot}
                  >
                    How long we keep your personal information
                  </Link>
                </p>
                <p>
                  <Link
                    to="poy"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    onClick={comot}
                  >
                    Protection of your personal information
                  </Link>
                </p>
                <p>
                  <Link
                    to="soy"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    onClick={comot}
                  >
                    Storage of Your Data
                  </Link>
                </p>
                <p>
                  <Link
                    to="yra"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    onClick={comot}
                  >
                    Your rights and how to contact us
                  </Link>
                </p>
                <p>
                  <Link
                    to="wac"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    onClick={comot}
                  >
                    What are Cookies?
                  </Link>
                </p>
                <p>
                  <Link
                    to="waco"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    onClick={comot}
                  >
                    Can I turn off cookies?
                  </Link>
                </p>
                <p>
                  <Link
                    to="cto"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    onClick={comot}
                  >
                    Changes to our Privacy PolicyStorage of Your Data
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="width">
          <p className="terms">
            This Privacy Policy is designed to help you understand what
            information we gather about you, what we use that information for,
            and with whom we share that information. It also sets out your
            rights in relation to your information and who you may contact for
            more information or queries. Please read the following carefully to
            understand our processes and practices regarding your personal data
            and how we will treat it.
            <br id="twt" />
            <br /> <br />
            <span> To whom this Privacy Policy applies and what it covers</span>
            <br />
            <br /> This Privacy Policy applies to Rex Financial Technologies
            Limited (<b>“we” or “us” or “our” </b> ). Rex Financial Technologies
            Limited recognizes the importance of protecting your personal and
            financial information and is committed to doing this.
            <br /> <br /> <br />
            This Privacy Policy sets out how we collect, handle, store and
            protect information about you when:
            <br />
            · We provide products or services to you or our client;
            <br />
            · You visit or use our website/App; and/or
            <br />
            · Performing any other activities that form part of our business.
            <br />
            This Privacy Policy also contains information about when we share
            your personal information with third parties (including, but not
            limited to, our service providers and credit reference agencies). In
            this Privacy Policy, we may sometimes collectively refer to
            handling, collecting, protecting and storing your personal
            information as “processing” such personal information.
            <br />
            <br id="owa" />
            <br /> <span> Other Website/Apps</span>
            <br /> <br />
            Please note that other website/Apps that may be linked to this
            website/App/App are not governed by this Privacy Policy. We
            encourage visitors to review the privacy policy on each of these
            other website/Apps before disclosing any personal information.
            <br />
            <br id="con" />
            <br />
            <span> Consent</span>
            <br /> <br />
            You agree with the contents of this Privacy Policy when you give
            consent upon access to our website/App, digital and non-digital
            platforms, or use our services, content, features, technologies or
            functions offered on our website/App or digital platforms or visit
            any of our offices for official or non-official purposes. Note that
            you can withdraw your consent at any time, however such withdrawal
            of consent would not affect the lawfulness of processing information
            based on consent given before its withdrawal.
            <br id="wiw" /> <br />
            <span> What information we collect</span>
            <br /> <br />
            In the course of providing products or services to you or our client
            and performing due diligence checks in connection with our products
            or services (or discussing the possible products or services we
            might provide), we generally collect personal information about you.
            We also typically collect personal information about you when you
            visit or use our website/App.
            <br />
            <br />
            We typically collect or obtain your personal information because you
            give it to us (for example, in a form on our website/App) or because
            other people give that information to us (for example, third party
            service providers that we use to help operate our website/App). We
            may also collect or obtain personal information from you because we
            observe or infer that information about you from the way you
            interact with us. In order to improve your experience when you use
            this website/App and to ensure that it is functioning effectively,
            we (or our service providers) also use cookies (small text files
            stored in a user’s browser) and web beacons (small graphic images
            that are placed on a website/App and used to monitor a user’s
            interaction with that website/App) which may collect personal
            information.
            <br />
            <br />
            Additional information on how we use cookies and other tracking
            technologies and how you can control these can be found in our
            Cookies Policy, which is included at the end of this document.
            <br />
            <br />
            The personal information that we may collect depends on the specific
            services, activities or products we undertake but typically
            includes: your name; age; date of birth; gender; home address; email
            address; BVN; telephone number; other contact details; country of
            residence; passport number and other national ID numbers; employment
            details (for example, the organization you work for, your job
            title); family circumstances (for example, your marital status where
            you enter it in a form on our website/App or it appears in any
            documentation you provide to us); financial information (for
            example, your income, bank statements, where you enter such
            information in a form on our website/App or it appears in any
            documentation that you provide to us or authorize us to access from
            your bank and financial institutions); details of how you use any
            product or service provided by Rex Financial Technologies Limited;
            postings on any blogs, forums, wikis, and any other social media
            applications and services that we provide; IP address; browser and
            device type; user name that is used by you for one of our products
            or services; language; access times and duration; details of how you
            like to use our website/App or the interactive products, tools,
            other technology or services we provide to you or our clients;
            website/Apps that you visited before and after visiting our
            website/App; details of how you like to interact with us, and
            similar information.
            <br />
            <br />
            The types of personal information and ‘sensitive’ or ‘special
            categories’ of personal information that we collect will generally
            vary depending on the nature of the products and services that we
            provide to you and how you use our website/App. In some cases, the
            ‘sensitive’ or ‘special categories’ of personal information that we
            collect may include information collected as part of KYC and
            anti-money laundering checks that we must conduct before accepting
            you as a customer.
            <br />
            <br />
            In some rare circumstances, we will also gather other ‘special
            categories’ of personal information about you because you volunteer
            that data to us. In some other circumstances, the personal
            information we collect from you is needed to meet our legal or
            regulatory obligations or to provide you with the products or
            services requested by you.
            <br />
            <br />
            In some cases, we may also collect personal information about you
            indirectly from third parties including but not limited to: (i) your
            employer; (ii) third parties such as providers of ‘know your client’
            and anti-money laundering services which we use to help us meet our
            legal requirements in this area and to help us verify your identity
            where we provide you with products or services; (iii) background
            check providers which we sometimes use to verify your identity when
            you apply for a product of ours; (iv) third party service providers
            that help us to operate our website/App; and (v) your banks and
            financial institutions.
            <br /> <br /> <br />
            <span className="big"> HOW WE USE INFORMATION ABOUT YOU</span>
            <br id="uop" /> <br />
            <span>
              Use of personal information to provide products and services
            </span>
            <br /> <br />
            We will use your personal information to provide you with products
            or services. As part of this, we may use your personal information
            in the course of correspondence relating to those products or
            services. Such correspondence may be with you, our customers, our
            service providers or public or judicial authorities with the
            necessary authorization.
            <br />
            <br />
            In many cases, we also use your personal information to conduct due
            diligence checks in advance of providing products or services to
            you, and to process an application from you or a prospective client
            to receive products or services from us. If you are referred to us
            by an intermediary or similar third party, we also typically use
            your personal information to assess whether to accept or reject your
            referral to Rex Financial Technologies Limited by that intermediary
            or similar third party.
            <br />
            <br id="upi" />
            <br />
            <span>
              Use of personal information collected via our Website/App
            </span>
            <br /> <br />
            We generally use your personal information collected via this
            website/App: <br />
            <br /> · to manage and improve any services provided via our
            website/App
            <br />· to manage and improve our website/App (including by drawing
            up statistics on the usage of our website/App)
            <br />· to tailor content of our website/App to provide you with a
            more personalised experience and draw your attention to information
            about products and services that may be of interest to you
            <br />· to manage and respond to any request that you submit through
            our website/App
            <br />· to help us learn more about you, the products and services
            that you receive from Rex Financial Technologies Limited, and other
            products and services that you or your employer might be interested
            in receiving
            <br />· to correspond with you in relation to services you use on
            our website/App or information you provide via our website/App. This
            correspondence is usually with you, our service providers, or public
            or judicial authorities with the necessary authorisation.
            <br />
            <br />
            We generally also use your Use of personal information for other
            activities that form part of the operation of our business personal
            information collected via this website/App for the purposes of, or
            in connection with:
            <br />
            <br />· applicable legal or regulatory requirements
            <br />· requests and communications from public or judicial
            authorities with the necessary authorisation· financial accounting,
            invoicing and risk analysis purposes
            <br />· prudent operational management (including credit and risk
            management, audit, training and similar administrative purposes)
            <br />· client relationship purposes, which involve: (i) contacting
            you to receive feedback on Rex Financial Technologies Limited
            products or services; and (iii) contacting you for other marketing
            or research purposes;
            <br />· recruitment and business development purposes· services we
            receive from our professional advisors, such as lawyers, accountants
            and consultants
            <br />· arrangements we have in place with intermediaries, brokers
            and other individuals and entities that partner with us
            <br />· protecting our rights and those of our customers· meeting
            our corporate and social responsibilities.
            <br />
            <br id="tlg" />
            <br />{" "}
            <span>
              The legal grounds we rely on for processing personal information{" "}
            </span>{" "}
            <br /> <br />
            We are required by law to set out in this Privacy Policy the legal
            grounds on which we rely in order to process your personal
            information. We generally use your personal information for the
            purposes outlined above because: (a) it is necessary for our
            legitimate interests and does not unduly affect your interests or
            fundamental rights and freedoms; (b) it is necessary for legal
            and/or regulatory obligations or requests that we are subject to,
            such as keeping records in accordance with applicable regulations or
            providing information to a public body or law enforcement agency;
            (c) it is necessary to perform contractual obligations that we owe
            towards you, or to take pre-contractual steps at the request of you;
            (d) in some cases, we have obtained your prior consent.
            <br />
            <br /> To the extent that we process any sensitive personal
            information relating to you for any of the purposes outlined above,
            we will do so because either: (1) you have given us your explicit
            consent to process that information; (2) the processing is necessary
            for reasons of substantial public interest on the basis of
            applicable law (for example where we are required by law or
            regulatory requirements to process that information in order to
            ensure we meet our KYC and ‘anti money laundering’ obligations); or
            (3) the processing is necessary for the establishment, exercise or
            defense of legal claims. The legal grounds for processing sensitive
            personal information outlined above are contained in the EU’s
            General Data Protection Regulation and the Nigeria Data Protection
            Regulation 2019.If you do not want to continue receiving any
            marketing materials from us, you may communicate this to us via
            recommended options. To whom we disclose your information We
            generally disclose details about you to professional advisors and
            third parties that provide services to us (such as IT systems
            providers, platform providers, financial advisors, consultants
            including lawyers and accountants) and other goods and services
            providers (such as providers of marketing services where we are
            permitted to disclose your personal information to them); competent
            authorities (including any national and/or international regulatory
            or enforcement body or court or other form of tribunal, where we are
            required to do so by applicable law or regulation at their request);
            a potential buyer, transferee, merger partner or seller and their
            advisers in connection with an actual or potential transfer or
            merger of part or all of Rex Financial Technologies Limited business
            or assets, or any associated rights or interests, or to acquire a
            business or enter into a merger with it; credit reference agencies
            or other organizations that help us detect criminal activity and
            incidence of fraud; and any federal, state or local government
            departments and other statutory or public bodies.
            <br /> <br id="hlw" />
            <br /> <span> How long we keep your personal information</span>
            <br /> <br />
            We will hold your personal information on Rex Financial Technologies
            Limited systems for as long as is necessary to fulfil the purpose
            for which it was collected or to comply with applicable legal,
            regulatory or internal policy requirements.
            <br /> <br id="poy" />
            <br /> <span>
              Protection of your personal information
            </span> <br /> <br />
            We use a range of physical, electronic and managerial measures to
            ensure that we keep your personal information secure, accurate and
            up to date. These measures include:· education and training of
            relevant staff to ensure they are aware of our privacy obligations
            when handling personal information as well as training around social
            engineering, phishing, spear phishing, and password risks·
            administrative and technical controls to restrict access to personal
            information on a ‘need to know’ basis· technological security
            measures, including fire walls, encryption and anti-virus software·
            physical security measures, such as staff security passes to access
            our premises.· external technical assessments, security audits and
            vendor due diligence· endpoint security: Anti-virus, portable
            storage device lockdown, restricted administrative privileges·
            Real-time monitoring of data leakage controls· Layered and
            comprehensive cybersecurity defences· Security incident reporting
            and management Although we use appropriate security measures once we
            have received your personal information, the transmission of data
            over the internet (including by e-mail) is never completely secure.
            We endeavour to protect personal information, but we cannot
            guarantee the security of data transmitted to us or by us.
            <br id="soy" />
            <br /> <span> Storage of Your Data</span> <br /> <br />
            The data that we collect from you will be transferred to and stored
            at a destination within Nigeria. By submitting your personal data,
            you agree to this transfer, storing or processing. We will take all
            steps reasonably necessary to ensure that your data is treated
            securely and in accordance with this privacy policy. All information
            you provide to us is stored on our secure cloud-based data storage
            as well as on premises, at an off-site based location and a network
            accessible storage which includes external drives only for
            authorized users.
            <br />
            <br />· We erase/delete personal data in the event of any of the
            following:
            <br />· i. The personal data is no longer necessary in relation to
            the purposes for which they were collected or processed;
            <br />
            ·ii. You withdraw your consent or object to the processing and there
            is no overriding lawful basis for the processing;
            <br />
            ·iii. The personal data was unlawfully collected or processed in the
            first place;
            <br />
            ·iv. In compliance with NITDA’s or any other lawful directive.
            <br id="yra" />
            <br /> <span> Your rights and how to contact us</span> <br />
            <br />· You have various rights in relation to your personal
            information. In particular, you have a right to: <br />
            <br />· object to the processing of your personal information
            <br /> · request a copy of personal information we hold about you
            <br /> · ask that we update the personal information we hold about
            you, or correct such personal information that you think is
            incorrect or incomplete
            <br /> · ask that we delete personal information that we hold about
            you, or restrict the way in which we use such personal information
            <br /> · withdraw consent to our processing of your personal
            information (to the extent such processing is based on consent)
            <br />· To exercise any of your rights, or if you have any other
            questions about our use of your personal information, please e-mail{" "}
            <a className="color" href="mailto:contact@rexafrica.com">
              contact@rexafrica.com
            </a>
            . You may also use these contact details if you wish to make a
            complaint to us relating to your privacy.
            <br />
            <br />· If you are unhappy with the way we handled your personal
            information or any privacy query or request that you have raised
            with us, you also have a right to complain to a data protection
            regulator in the place where you live or work, or in the place where
            you think an issue in relation to your data has arisen.
            <br />
            <br id="cto" />
            <br /> <span> Changes to our Privacy Policy</span> <br /> <br />
            This Privacy Policy may be updated from time to time for any reason.
            The privacy policy was last updated on 30th June 2023. <br />
            <br />
            <span className="big" id="cookie" name="cookie"> Cookie Policy</span>
            <br id="wac" />
            <br /> <span> What are Cookies? </span> <br />
            <br />
            Cookies are brief text documents. They are frequently obtained from
            websites and apps that you use on your computer or mobile device.
            They make it possible for our website/App to identify your device
            while you navigate the site (and possibly when you return at a later
            time) so that, for instance, you don't have to enter your password
            each time you switch between pages.
            <br id="waco" />
            <br /> <span> Can cookies be disabled?</span> <br /> <br />
            You can configure your web browser to disable cookies if you do not
            want to receive them. Cookies enable you to get the most out of our
            website/app, as was previously said. You might discover that some
            features of our website or App do not function if you choose to
            disable cookies.
            <br />
            <br /> <span> How do we use Cookies?</span> <br /> <br />
            Cookies recognize you, remember preferences, and provide a
            personalized experience on our website/app. They also make
            interactions with Rex Africa faster and more secure, while recording
            your actions and preferences over time, including login information,
            user history, language, font, and color settings.
            <br />
            <br />
            Authentication: When you are logged into your Rex Africa account,
            cookies enable us to display the pertinent data and customise your
            experience.
            <br />
            <br />
            Security: To support our security features, track malicious
            behaviour, and spot violations of our user agreement, we utilise
            cookies.
            <br />
            <br />
            Favourite Features, Services, and Features: We can recognise your
            preferred language and method of communication thanks to cookies.
            <br />
            <br />
            <br /> <span> Accepting cookies on our website</span> <br /> <br />
            When you first access our website or app, a cookies banner will
            appear, giving you the option to:
            <br />
            <br />
            By selecting "Accept all Cookies," you agree to the usage of cookies
            on our website/app. <br />
            <br />
            "Reject all Cookies" indicates that you do not want to have cookies
            used on our website or app.
            <br />
            <br />
            By visiting our website, selecting the cookie settings button, or by
            checking out our cookie policy (link to cookie policy), you can
            change your consent preference at any time.
            <br />
            <br />
            <span> How to delete or disable cookies in your browser</span>{" "}
            <br />
            By changing the settings of your web browser, you can turn off
            cookies. Please be advised that by taking this step, certain
            functionality, like the "keep me signed in" and "remember me"
            features, may be lost.
            <br />
            <br /> 
            <div> Our Cookie Policy Changes</div> <br />
        
            Periodically, this Cookie Policy may be amended. Any changes will be
            noted on this page, and they'll be effective as soon as they're
            published. The last time this cookie policy was modified was on July
            25, 2023
            <br />
      
            <div> CONTACT US</div> <br />
            If you have questions about this Privacy Policy, please contact us by email at Support@rexafrica.com
            <br />
            <br />

            
          </p>
        </div>
      </div>
    </div>
  );
}
