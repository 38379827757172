import { Link } from "react-scroll";
import { useState } from "react";
import icon2 from "../../image/Arrow - Down 2.png";
import Header from "../header/Header";
import "./terms.css";

export default function LoansForm() {
  const [show, setshow] = useState(false);

  const handleclick = () => {
    setshow(!show);
  };

  const comot = () => {
    setshow(false);
  };

  const iframeDiveStyle = {
    color: 'blue',
    lineHeight: 10,
    padding: '1.5em',
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '30px',
    marginLeft: '30px',
 }

 const iframeStyle = {
    minHeight: '100vh',
    height: '140 rem',
    padding: '5px'
 }
  return (
    <div className="terms">
      <Header dark />

      

<div style={iframeDiveStyle}>

<iframe allowtransparency="true" width="100%" style={iframeStyle} allowfullscreen="allowfullscreen" frameborder="0"  src="https://x.loandisk.com/form_api/iframe.php?formSlug=GwKbHmSYOecxkLIV9avs62FAZEgzJ7"></iframe>
</div>
      
    </div>
  );
}
