import Create from "../../components/create/Create";
import Frequently from "../../components/faq/Faq";
import Header from "../../components/header/Header";
import Loans from "../../components/Loans/Loans";
import app from "../../image/Group 33625.svg";
import app2 from "../../image/Group 33626.svg";
import phone from "../../image/iPhone12.png";
import "./credit.css";

export default function Credit() {
  return (
    <div className="credit">
      <Header dark />
      <div className="home">
        <div className="body width">
          <div className="talk">
            <div className="cspi">
              {" "}
              <span> Credit</span>{" "}
            </div>
            <p>
              <span>Quick loans</span> when you <br /> need them
            </p>
            <p className="twentyone">
              Get a loan for your personal and business needs today. Rex <br />{" "}
              offers flexible loans to help you make that payment, acquire that{" "}
              <br /> asset, and solve your financial needs
            </p>
            <p>Get started on rex</p>

            <div className="button">
              <img src={app2} alt="" />
              <img src={app} alt="" />
            </div>
          </div>
          <div className="img">
            <img src={phone} alt="" />
          </div>
        </div>
      </div>

      <div className="width">
        <div className="service">
          <p>How it works</p>
          <p>Getting a loan on Rex is as easy as A, B, C</p>
          <p>Download the app and follow the steps below to secure a loan</p>
        </div>
      </div>
      <Create />
      <Loans />
      <Frequently />
    </div>
  );
}
