import "./someof.css";
// import burger from "../../image/logos/image 10.png";
// import thrive from "../../image/logos/image 11.png";
// import rex from "../../image/logos/Frame.png";
// import access from "../../image/logos/image 12.png";
// import sl from "../../image/logos/image 13.png";
import all from "../../image/new/Frame 33811.png";

export default function Someof() {
  return (
    <div className="someof">
      <div className="width">
        <p>Some of the amazing businesses using Rex</p>
        <div className="img">
          <img src={all} alt="" />
          {/* <div className="image">
            <img src={burger} alt="" />
          </div>
          <div className="image">
            <img src={thrive} alt="" />
          </div>
          <div className="image">
            <img src={rex} alt="" />
          </div>
          <div className="image">
            <img src={access} alt="" />
          </div>
          <div className="image">
            <img src={sl} alt="" />
          </div>
          <div className="image">
            <img src={rex} alt="" />
          </div> */}
        </div>
      </div>
    </div>
  );
}
