import "./take.css";
import bg from "../../image/Group 33658.png";
import app from "../../image/Group 33656.png";
import app2 from "../../image/Group 33657.png";

export default function Take({ darkurl }) {
  console.log(darkurl);
  return (
    <div className="take">
      <div className="width">
        <div className="talkpic">
          <div className="talk">
            <p className="fifty">
              Take charge with Rex. <br /> Africa’s financial super app
            </p>
            <p className="twentyone">
              Our mission is to uplift the financial well being of individuals{" "}
              <br /> and small businesses across Africa.
            </p>

            <p>Get started today</p>

            <div className="button">
              <img src={app2} alt="" />
              <img src={app} alt="" />
            </div>
          </div>
          <div className="pic">
            <img src={bg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
