import React, { useState } from "react";
// import pic from "../../image/Group 33689.png";
import zero from "../../image/zero.png";
import submit from "../../image/submit.png";
import provide from "../../image/provide.png";
import start from "../../image/start.png";
import "./create.css";

export default function Create() {
  const [no, setNo] = useState(0);
  const [image, setimage] = useState(zero);
  // console.log(slider);

  const details = [
    {
      first: "Create an account on Rex",
      second:
        "Add your email and other necessary details, and log in to the app.",
    },
    {
      first: "Start your loan application",
      second: "Choose the amount you need and for how long",
    },
    {
      first: "Provide your bank statement",
      second:
        "Submit your account statement to allow us determine your eligibility.",
    },
    {
      first: "Submit your application",
      second: "Review the offer and accept",
    },
  ];

  const dosomething = (id) => {
    setNo(id);

    if (id === 0) {
      setimage(zero);
    } else if (id === 1) {
      setimage(start);
    } else if (id === 2) {
      setimage(provide);
    } else {
      setimage(submit);
    }
  };

  return (
    <div className="create">
      <div className="width">
        <div className="imagecreate">
          <div className="image">
            <img src={image} alt="" />
          </div>
          <div className="create">
            {details.map((detail, id) => (
              <div
                key={id}
                onClick={() => dosomething(id)}
                className={no === id ? "account active" : "account"}
              >
                <p>{detail.first}</p>
                <p>{detail.second}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
