import Control from "../../components/Control/Control";
import Getaccess from "../../components/getaccess/Getaccess";
import Getmore from "../../components/getmore/Getmore";
import Header from "../../components/header/Header";
import Yourfav from "../../components/yourfav/Yourfav";
import Yourmoney from "../../components/Yourmoney/Yourmoney";

import app from "../../image/Group 33625.svg";
import app2 from "../../image/Group 33626.svg";
// import phone from "../../image/iPhone (1) (1).png";
// import card from "../../image/Card.png";
import gif from "../../image/gif.gif";
import "./homepage.css";

export default function Homepage() {
  return (
    <div className="homepage">
      <div className="home">
        <Header dark />
        <div className="body width">
          <div className="talk">
            <div className="cspi"></div>

            <p>
              Do More with Your <br /> Funds. We are{" "}
              <span>
                More <br /> than Just a Bank
              </span>
            </p>
            <p className="twentyone">
              Rex is a Neobank that provides financial services to
              <br /> individuals and small businesses in Africa. We give you{""}
              <br /> the tools to control your finances. Get a free bank
              account,{""}
              <br /> save, invest and get quick loans on Rex.
            </p>
            <p>Get started today</p>

            <div className="button">
              <img src={app2} alt="" />
              <img src={app} alt="" />
            </div>
          </div>
          <div className="img">
            <img src={gif} alt="" />
            {/* <div className="card">
              <img src={card} alt="" />
            </div>{" "}
            <div className="phone">
              <img src={phone} alt="" />
            </div> */}
          </div>
        </div>
      </div>
      <Yourmoney />
      <Getmore />
      <Getaccess />
      <Yourfav />
      <Control />
    </div>
  );
}
