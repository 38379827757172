import "./grow.css";
import money from "../../image/Group 33665.png";
import woman from "../../image/wom.png";
import re from "../../image/re.png";
import { Link } from "react-router-dom";

export default function Grow() {
  return (
    <div className="grow">
      <div className="width">
        <div className="first">
          <p className="fifty">Grow further with Rex</p>
          <p className="twentyone">
            Get information on latest updates from Rex and our partners, as well
            as resources to improve your operations.
          </p>
        </div>

        <div className="second">
          <div className="three">
            <Link to="/blog">
              <div className="image">
                <img src={money} alt="" />
              </div>
              <p>Embedded Finance: The Future of Finance in Africa</p>
              <p>
                Read this article by our CEO, Yinka Odeleye, on the future of
                finance in Nigeria and Africa
              </p>
              <p></p>
            </Link>
          </div>

          <div className="three">
            <Link to="/blog2">
              <div className="image">
                <img src={re} alt="" />
              </div>
              <p>Rex launches mobile app</p>
              <p>
                We recently launched the first version of our mobile
                application. Download on Google Playstore to access our
                services.
              </p>
              <p></p>
            </Link>
          </div>

          <div className="three">
            {" "}
            <Link to="/blog3">
              <div className="image">
                <img src={woman} alt="" />
              </div>
              <p>4 Ways to manage FX Risk as a business</p>
              <p>
                This article shares insights on how businesses can better manage
                their operations in light of foreign exchange flunctuations.
              </p>
              <p></p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
