import { Link } from "react-scroll";
import { useState } from "react";
import icon2 from "../../image/Arrow - Down 2.png";
import Header from "../header/Header";
import "./terms.css";

export default function Terms() {
  const [show, setshow] = useState(false);

  const handleclick = () => {
    setshow(!show);
  };

  const comot = () => {
    setshow(false);
  };
  return (
    <div className="terms">
      <Header dark />

      <div className="ourterms">
        <div className="width">
          <div className="top">
            <p>Terms and Conditions</p>
            <p>Last Updated: 30th June 2023</p>
          </div>

          <div className="input" onClick={handleclick}>
            <input
              type="text"
              placeholder="Select a section of our terms and conditons"
            />
            <img src={icon2} alt="" />

            <div className={show ? "drop" : "none"}>
              <p>
                <Link
                  to="gen"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  onClick={comot}
                >
                  GENERAL TERMS
                </Link>
              </p>
              <p>
                <Link
                  to="now"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  onClick={comot}
                >
                  NO WARRANTY
                </Link>
              </p>
              <p>
                <Link
                  to="vir"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  onClick={comot}
                >
                  VIRUSES
                </Link>
              </p>
              <p>
                <Link
                  to="lim"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  onClick={comot}
                >
                  LIMITATION OF LIABILITY
                </Link>
              </p>
              <p>
                <Link
                  to="ind"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  onClick={comot}
                >
                  INDEMNITY
                </Link>
              </p>
              <p>
                <Link
                  to="aut"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  onClick={comot}
                >
                  AUTHORISED USE
                </Link>
              </p>
              <p>
                <Link
                  to="lin"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  onClick={comot}
                >
                  LINKS TO THIS WEBSITE/MOBILE APP
                </Link>
              </p>
              <p>
                <Link
                  to="cop"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  onClick={comot}
                >
                  COPYRIGHTS AND TRADE MARKS
                </Link>
              </p>
              <p>
                <Link
                  to="sec"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  onClick={comot}
                >
                  SECURITY
                </Link>
              </p>
              <p>
                <Link
                  to="gov"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  onClick={comot}
                >
                  GOVERNING LAW
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="width">
        <p className="terms" id="gen">
          <span> General Terms</span> <br />
          <br />
          Rex Financials Technology maintains this Website and Mobile App to
          provide you with information about products and services available
          through us, and information about RexCredit Limited. Please read these
          Terms and Conditions carefully before accessing or using this
          Website/Mobile App. <br />
          <br /> By accessing or using the Website/Mobile App, you agree to
          these Terms and Conditions. Rex Financials Technology reserves the
          right to change, modify, add or remove portions of the terms at any
          time. Please check these terms periodically for changes. <br />
          <br />
          If you do not wish to be bound by this agreement, do not access the
          Website/Mobile App. If you have any questions about this Agreement,
          please contact us via email at contact@rexcred.com.
          <br id="now" />
          <br /> <span> NO WARRANTY </span> <br />
          <br />
          We created this Website/Mobile App/ for the convenience of our
          Internet visitors. The information (including text, graphics and
          functionality) is presented “as is” and “as available” without express
          or implied warranties including, but not limited to, implied
          warranties of non-infringement, merchantability and fitness for a
          particular purpose. We expressly disclaim any liability for errors and
          omissions regarding the information and materials contained in the
          Website/Mobile App. Due to the nature of the Internet, we cannot
          guarantee the accuracy or completeness of the information contained in
          this Website/Mobile App or its suitability for any purpose.
          <br id="vir" />
          <br /> <span> VIRUSES </span> <br />
          <br />
          Due to the marked increase in the fabrication and proliferation of
          computer viruses affecting the Internet, we want to warn you about
          infections or viral contamination on your system. It is your
          responsibility to scan any and all downloaded materials received from
          the Internet. We are not responsible or liable for any damage caused
          by such hazards.
          <br id="lim" />
          <br /> <span> LIMITATION OF LIABILITY</span> <br />
          <br />
          Your use of the Website/Mobile App is at your own risk. To the maximum
          extent permitted by law, neither Rex Financials Technology nor any
          other party involved in creating, producing or delivering the
          Website/Mobile App/, will be liable for any direct, indirect,
          incidental, consequential or punitive damages (including, without
          limitation, loss of profits, cost of substitute service or lost
          opportunity), howsoever caused, arising out of your access to, use of,
          or reliance on the Website/Mobile App, even if Rex Financials
          Technology has been advised of the possibility of such damages.
          Without limiting the foregoing, Rex Financials Technology assumes no
          responsibility for, and will not be liable for any damages relating to
          or caused by any viruses which may affect your computer equipment or
          other property on account of your access to, use of, or downloading
          from, the Website/Mobile App/Mobile App. Rex Financials Technology
          cannot and does not guarantee continuous, uninterrupted or secure
          access to the Website/Mobile App/Mobile App. You specifically
          acknowledge and agree that Rex Financials Technology is not liable for
          any defamatory, offensive, fraudulent, or otherwise illegal conduct of
          any user.
          <br id="ind" />
          <br /> <span> INDEMNITY</span> <br />
          <br />
          You agree to indemnify and hold RexCredit Limited, its subsidiaries or
          affiliates, and their respective directors, officers, employees, and
          agents, harmless against any and all liabilities, claims and expenses,
          including reasonable attorneys’ fees, arising from breach of these
          Terms and Conditions, any other related policy, your use or access of
          the Website/Mobile App or any Internet site linked to or from the
          Website/Mobile App, or in connection with the transmission of any
          content on the Website/Mobile App.
          <br id="aut" />
          <br /> <span> AUTHORISED USE</span> <br />
          <br />
          The information provided on this Website/Mobile App is solely for the
          personal use of Website/Mobile App users. You are authorised to view
          and copy the information available on this Website/Mobile App for
          personal purposes only, provided that any copies include any trade
          mark notices as they may appear on those pages copied. Except for the
          personal use of Website/Mobile App users, you may not copy, reproduce,
          modify, display, distribute, perform, disseminate, transmit, transfer,
          sell, license, publish, use to create a derivative work, or use for
          any public or commercial purposes any of the contents of this
          Website/Mobile App without the express prior written consent of
          RexCredit Limited. You agree to access and use the Website/Mobile App
          only for lawful purposes.
          <br id="lin" />
          <br /> <span>LINKS TO THIS WEBSITE/MOBILE APP </span> <br />
          <br />
          Rex Financials Technology has not reviewed all of the sites which are
          linked to the Website/Mobile App/, and the fact of such links does not
          indicate any endorsement, authorisation, sponsorship or affiliation
          with respect to the material contained on any linked site. Rex
          Financials Technology is not responsible for the contents of any site
          linked to the Website/Mobile App. Your connection to and use of any
          such linked site is at your own risk.
          <br id="cop" />
          <br /> <span>COPYRIGHTS AND TRADE MARKS </span> <br />
          <br />
          Copyrights of the pages and the screens displaying the pages, and the
          information and material therein, is owned by Rex Financials
          Technology unless otherwise indicated and may not be distributed,
          modified, reproduced in whole or in part without the prior written
          permission of RexCredit Limited. The display of trade marks herein
          does not imply that a license of any kind has been granted. Any
          downloading, re-transmission, or other copying or modification of the
          trademarks and/or the contents herein may be a violation of applicable
          laws and regulations and could subject the copier to legal action. The
          trademarks and logos (collectively the “Trade Marks”) displayed on
          this site, unless otherwise indicated, are registered and unregistered
          trademarks of Rex Financials Technology and its subsidiaries and
          affiliates. Rex Financials Technology and its trademarks may only be
          used publicly with the permission of Rex Financials Technology and
          with proper acknowledgement. Use of any Rex Financials Technology
          trademarks without the prior written consent of Rex Financials
          Technology may constitute copyright infringement or passing off in
          violation of applicable laws.
          <br id="sec" />
          <br /> <span>SECURITY </span> <br />
          <br />
          We welcome your email correspondence. However, internet and email
          communications are not confidential. It is possible that information
          transmitted to us may be read or obtained by other parties. In an
          attempt to protect your privacy, our email responses do not include
          personal account information such as your BVN or bank account numbers.
          Email is an important communication channel for our Website/Mobile
          App’s visitors. We will use your email address and the content of any
          email for correspondence purposes and to meet our legal and regulatory
          requirements regarding customer communications. On occasion, we may
          use your email address to send you communications with information
          about service and product information that we believe may be of
          interest to you. If you do not want to receive such unsolicited
          communications from us, please send us a message to this effect.
          <br id="gov" />
          <br /> <span>GOVERNING LAW </span> <br />
          <br />
          You agree that the use of this site shall be governed by and construed
          in accordance with the laws of the Federal Republic of Nigeria.
        </p>
      </div>
    </div>
  );
}
