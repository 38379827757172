import "./payment.css";
import React, { useState } from "react";
import Header from "../../components/header/Header";
import app from "../../image/Group 33625.svg";
import app2 from "../../image/Group 33626.svg";
import phone from "../../image/Frame 33678.png";
import icon from "../../image/Group 33576 (3).png";
import icon2 from "../../image/Group 33576 (4).png";
import icon3 from "../../image/Group 33576 (5).png";
import p4 from "../../image/p4.png";
import p1 from "../../image/p1.png";
import p2 from "../../image/p2.png";
import p3 from "../../image/p3.png";
import phone1 from "../../image/iPhone4.png";
import Faq from "react-faq-component";

export default function Payment() {
  const [index, setIndex] = useState(0);
  const [slider, setslider] = useState({ image: p1, no: index });

  const dosomething = (id) => {
    // setNo(id);
    setIndex(id);

    if (id === 0) {
      setslider({ image: p1, no: id });
    } else if (id === 1) {
      setslider({ image: p2, no: id });
    } else if (id === 2) {
      setslider({ image: p3, no: id });
    } else {
      setslider({ image: p4, no: id });
    }
  };

  const data = {
    rows: [
      {
        title: "How can I make payments?",
        content: `Making payments on Rex is easy. Simply select the type of payment you want to do, add details and complete in the 
        shortest time possible.`,
      },
      {
        title: "How can I make automatic payments on Rex?",
        content:
          "Simply save a beneficiary and make a particular payment recurring.",
      },
      {
        title: "Do I get points for making payments on Rex?",
        content: `At the moment, no. We will update you when this is available.`,
      },
    ],
  };

  const details = [
    {
      image: p1,
      first: "Download the app on Google play store and Apple store",
      second:
        "Create an account on Rex, provide all the necessary details and login",
    },
    {
      image: p2,
      first: "Choose payment type",
      second: "Select payment category (transfer or bill payments)",
    },
    {
      image: p3,
      first: "Add transaction details ",
      second: "Input the details of your recipient.",
    },
    {
      image: p4,
      first: "Activate recurring payment",
      second: "Select recurring payment option if applicable.",
    },
  ];

  const styles = {
    // bgColor: 'white',
    titleTextColor: "blue",
    rowTitleColor: "#7399EF",
    rowContentColor: "#121212",
    arrowColor: "#121212",
    rowTitleTextSize: "23px",
    rowContentPaddingTop: "20px",
    rowContentTextSize: "18px",
    rowContentPaddingBottom: "20px",
  };

  const config = {
    // animate: true,
    // arrowIcon: "V",
    tabFocus: true,
    openOnload: 1,
    expandIcon: "+",
    collapseIcon: "-",
  };
  return (
    <div className="payment">
      <div className="savings">
        <div className="credit">
          <Header dark />
          <div className="home">
            <div className="body width">
              <div className="talk">
                <div className="cspi">
                  {" "}
                  <span>Payment </span>{" "}
                </div>
                <p>
                  <span> Easy payments </span>
                  <br />
                  Smooth transactions
                </p>
                <p className="twentyone">
                  Pay your bills, friends, family, partners, and vendors with
                  Rex
                </p>
                <p>Get started on rex</p>

                <div className="button">
                  <img src={app2} alt="" />
                  <img src={app} alt="" />
                </div>
              </div>
              <div className="img">
                <img src={phone1} alt="" />
              </div>
            </div>
          </div>
          <div className="yourmoney">
            <div className="width">
              <div className="put">
                <p></p>
                <p></p>
                <p className="twentyone"></p>
              </div>

              <div className="phonetext">
                <div className="phone">
                  <img src={phone} alt="" />
                </div>
                <div className="text">
                  <div className="save">
                    <p className="fifty">
                      All things payment, <br /> in one place
                    </p>
                    <p className="twentyone">
                      Pay your bills seamlessly with Rex
                    </p>
                  </div>
                  <div className="auto">
                    <div>
                      <img src={icon} alt="" />
                    </div>

                    <div className="talk">
                      <p>Send money 24/7</p>
                      <p className="twentyone">
                        Transfer funds whenever you need to.
                      </p>
                    </div>
                  </div>
                  <div className="auto">
                    <div>
                      <img src={icon2} alt="" />
                    </div>

                    <div className="talk">
                      <p>Recharge airtime</p>
                      <p className="twentyone">
                        Top up your airtime and stay connected to all the
                        important people in your life
                      </p>
                    </div>
                  </div>
                  <div className="auto">
                    <div>
                      <img src={icon3} alt="" />
                    </div>

                    <div className="talk">
                      <p>Pay your bills</p>
                      <p className="twentyone">
                        Pay for your daily needs on Rex
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="width">
            <div className="service">
              <p></p>
              <p>Payments on Rex in 4 easy steps</p>
              {/* <p>
                Make quick payments without stress today. It is easier, it is
                smoother, <br /> it is a better way to make payments.
              </p> */}
            </div>
          </div>

          <div className="create">
            <div className="width">
              <div className="imagecreate">
                <div className="image">
                  <img src={slider.image} alt="" />
                </div>
                <div className="create">
                  {details.map((detail, id) => (
                    <div
                      key={id}
                      onClick={() => dosomething(id)}
                      className={
                        slider.no === id ? "account active" : "account"
                      }
                    >
                      <p>{detail.first}</p>
                      <p>{detail.second}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* faq */}
          <div className="frequently">
            <div className="width">
              <div>
                <div className="answer">
                  <p>Frequently asked questions</p>
                  <p>Let's answer some of your questions</p>
                </div>
                <Faq data={data} styles={styles} config={config} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
