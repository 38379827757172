import "./yourfav.css";
import p1 from "../../image/p1.jpg";
import p2 from "../../image/p2.jpg";
import p3 from "../../image/p3.jpg";
import p4 from "../../image/p4.jpg";
import p5 from "../../image/p5.jpg";
import p6 from "../../image/p6.jpg";

export default function Yourfav() {
  return (
    <div className="yourfav">
      <div className="width">
        <div className="yourmoney">
          <div className="put">
            <p>Buy Now, Pay Later</p>
            <p>
              Buy your favorite appliances,
              <br /> and pay later at
              <span> 0% Interest.</span>
            </p>
            <p className="twentyone">
              With just 50% down payment and balance spread over a few months,
              you can get your choice items and <br /> reduce the strain on your
              finances. Give it a try, start with one household item today.
            </p>
          </div>
        </div>{" "}
        <div className="image g">
          <div className="gal">
            <img src={p1} alt="" />
          </div>
          <div className="gal">
            <img src={p2} alt="" />
          </div>
          <div className="gal">
            <img src={p3} alt="" />
          </div>
        </div>
        <div className="image">
          <div className="gal">
            <img src={p4} alt="" />
          </div>
          <div className="gal">
            <img src={p5} alt="" />
          </div>
          <div className="gal">
            <img src={p6} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
