import "./footer.css";
import rex from "../../image/Frame.png";
import three from "../../image/1.png";
import two from "../../image/2.png";
import one from "../../image/3.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <div className="width">
        <div className="every">
          <div className="logo">
            <div className="image">
              <img src={rex} alt="" />
            </div>
            <p>
              <a
                href="https://www.google.com/maps/place/3+Force+Rd,+Lagos+Island+102273,+Lagos/@6.4444633,3.3986423,17z/data=!3m1!4b1!4m5!3m4!1s0x103b8b18ac0693db:0x555d05d7ecc018fa!8m2!3d6.4444633!4d3.400831"
                target="_blank"
                rel="noreferrer"
              >
                3B Force Road, Onikan Lagos
              </a>
              <br /> <a href="tel:07025027000">07025027000</a>
            </p>

            <div className="socials">
              <a href="https://web.facebook.com/rexafricaofficial">
                <img src={two} alt="" />
              </a>
              <a href="https://twitter.com/rexafrica_">
                <img src={one} alt="" />
              </a>
              <a href="https://www.linkedin.com/company/rexafrica/mycompany">
                <img src={three} alt="" />
              </a>
            </div>
          </div>
          <div className="list">
            <div className="two">
              <ul>
                <li>Personal banking</li>
                <li>
                  <Link to="/savings">Savings</Link>
                </li>
                <li>
                  <Link to="/fixed">Investments</Link>
                </li>
                <li>
                  <Link to="/credit">Loans</Link>
                </li>
                <li>
                  <Link to="/bnpl">BNPL</Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link to="/forbusiness">Business banking</Link>
                </li>
                <li>
                  <Link to="/forbusiness">Working Capital</Link>
                </li>
                <li>
                  <Link to="/forbusiness">Supplier Finance</Link>
                </li>
                <li>
                  <Link to="/forbusiness">Invoice Discounting</Link>
                </li>
                <li>
                  <Link to="/forbusiness">Float Financing</Link>
                </li>
              </ul>
            </div>
            <div className="two">
              <ul>
                <li>Embedded banking</li>
                <li>NUBAN Issuance</li>
                <li>Fund Transfer</li>
                <li>KYC Verification</li>
                <li>Payments</li>
                <li>Card Issuance</li>
                <li className="cs">Coming soon</li>
              </ul>
              <ul>
                <li>About us</li>
                <li>Contact us</li>
                <li>Partners</li>
                <li>
                  <Link to="/privacy">Privacy </Link>
                </li>
                <li>
                  <Link to="/privacy#cookie"> Cookie Policy</Link>
                </li>
                <li>
                  <Link to="/terms">
                    Terms and <br /> Conditions
                  </Link>
                </li>
              </ul>
            </div>
            <div className="two">
              <ul>
                <li>Resources</li>
                <li>FAQs</li>
                <li>Press</li>
                <li>Help Center</li>

                <li className="cs">Coming soon</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
