import "./loans.css";
import phone from "../../image/Group 33672.png";
import icon from "../../image/Group 33576.svg";
import icon2 from "../../image/Group 33576 (2).svg";
// import icon3 from "../../image/Group 33576 (1).svg";
import icon4 from "../../image/Group 335.svg";

export default function Loans() {
  return (
    <div className="loans">
      <div className="yourmoney">
        <div className="width">
          <div className="put">
            <p></p>
            <p>Loans for your everyday needs</p>
            <p className="twentyone">
              Our quick and flexible loan helps you to meet your <br />{" "}
              financial needs.
            </p>
          </div>

          <div className="phonetext">
            <div className="phone">
              <img src={phone} alt="" />
            </div>
            <div className="text">
              <div className="auto">
                <div>
                  <img src={icon} alt="" />
                </div>

                <div className="talk">
                  <p>Get Loans 24/7</p>
                  <p className="twentyone">
                    Click a button and get the loan you need anytime any day.
                    Download the app, apply, share the required documents and
                    get a loan offer.
                  </p>
                </div>
              </div>
              <div className="auto">
                <div>
                  <img src={icon2} alt="" />
                </div>

                <div className="talk">
                  <p>No Collaterals</p>
                  <p className="twentyone">
                    You don’t need collateral to get a loan on Rex. Our loans
                    are flexible
                  </p>
                </div>
              </div>
              <div className="auto">
                <div>
                  <img src={icon4} alt="" />
                </div>

                <div className="talk">
                  <p>Improve your credit score</p>
                  <p className="twentyone">
                    Get a loan on Rex app, pay on time and improve your credit
                    score. This helps you access higher loan amounts when next
                    you apply.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
