import "./thecredit.css";
import man from "../../image/new/Mask group (1).png";
import girl from "../../image/new/Mask group (1) (1).png";
import big from "../../image/new/Mask group (2) (1).png";
import arrow from "../../image/new/Arrow - Right.png";

export default function Thecredit() {
  return (
    <div className="thecredit">
      <div className="width">
        <div className="service">
          <p>Our Services</p>
          <p>All your credit needs are sorted with Rex</p>
          <p>Get the financing to scale your business today</p>
        </div>

        <div className="working">
          <div className="float">
            <div className="img">
              <img src={man} alt="" />
            </div>
            <div className="talk">
              <p>Working Capital & Asset Finance</p>
              <p>
                Get credit to keep your business lights on, expand operations
                and acquire assets. Rex is here to provide you with the loan you
                need today.{" "}
              </p>
              <p>
                Get a working capital loan
                <img src={arrow} alt="" />
                [Coming soon]
              </p>
            </div>
          </div>
          <div className="float">
            <div className="img">
              <img src={girl} alt="" />
            </div>
            <div className="talk">
              <p>Float Finance</p>
              <p>
                Are you a mobile money operator, agent, retailer or merchant?.
                Get short term liquidity from Rex to support your operations and
                maximize your profits.
              </p>
              <p>
                Get Started
                <img src={arrow} alt="" /> [Coming soon]
              </p>
            </div>
          </div>
        </div>

        <div className="working two">
          <div className="float two">
            <div className="img">
              <img src={big} alt="" />
            </div>
            <div className="talk">
              <p>Supplier Finance</p>
              <p>
                Get funding to complete a supply, fulfill an order or discount
                an invoice.
              </p>
              <p>
                Access this fund <img src={arrow} alt="" /> [Coming soon]
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
