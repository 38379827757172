import ScrollToTop from "././components/ScrollToTop";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./pages/homepage/Homepage";
import Business from "./pages/Business/Business";
import Take from "./components/take/Take";
import Footer from "./components/footer/Footer";
import Credit from "./pages/credit/Credit";
import Savings from "./pages/Savings/Savings";
import Payment from "./pages/Payment/Payment";
import Fixed from "./pages/fixed/Fixed";
import Bnpl from "./pages/bnpl/Bnpl";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import Terms from "./components/terms/Terms";
import Privacy from "./components/terms/Privacy";
import Blog1 from "./pages/blog/Blog1";
import Blog2 from "./pages/blog/Blog2";
import Blog3 from "./pages/blog/Blog3";
import CookiePopup from "./components/Cookie/Cookie";
import Loans from "./components/Loans/Loans";
import LoansForm from "./components/terms/Loan";
// import Faq from "./pages/faq/Faq";
// import Blog4 from "./pages/blog/Blog4";

function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyBJBG2ZmQq2aaGtovZGCodvQImHXelt0mk",
    authDomain: "rex-financial.firebaseapp.com",
    databaseURL: "https://rex-financial-default-rtdb.firebaseio.com",
    projectId: "rex-financial",
    storageBucket: "rex-financial.appspot.com",
    messagingSenderId: "726376962984",
    appId: "1:726376962984:web:2dbeee80446241f1351b9d",
    measurementId: "G-6RPQCSCE52",
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  logEvent(analytics, "notification_received");

  (function () {
    var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/62b972c07b967b117996aa26/1g6i6ggl9";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
  })();

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Homepage />}></Route>
          <Route path="/forbusiness" element={<Business />} />
          <Route path="/credit" element={<Credit />} />
          <Route path="/savings" element={<Savings />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/fixed" element={<Fixed />} />
          <Route path="/bnpl" element={<Bnpl />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/blog" element={<Blog1 />} />
          <Route path="/blog2" element={<Blog2 />} />
          <Route path="/blog3" element={<Blog3 />} />
          <Route path="/loan" element={<LoansForm />} />
          {/* <Route path="/blogmain" element={<Blog4 />} /> */}
          {/* <Route path="/faq" element={<Faq />} /> */}
        </Routes>

        <Take />
        <CookiePopup />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
