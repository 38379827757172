import Header from "../../components/header/Header";
import app from "../../image/Group 33625.svg";
import app2 from "../../image/Group 33626.svg";
import phone from "../../image/another.png";
import icon from "../../image/Group 33576 (3).png";
import icon2 from "../../image/Group 33576 (4).png";
import icon3 from "../../image/Group 33576 (5).png";
// import pic from "../../image/Group 33689.png";
import phone1 from "../../image/iPhone122.png";
import Faq from "react-faq-component";
import one from "../../image/new/Group 33689.png";
import two from "../../image/new/two.png";
import three from "../../image/new/three.png";
import four from "../../image/new/four.png";
import "./savings.css";
import { useState } from "react";

export default function Savings() {
  const [no, setNo] = useState(0);
  const [image, setimage] = useState(one);

  // console.log(slider);

  const details = [
    {
      first: "Create an account on Rex",
      second: "Add your email and other details, and log in to the app",
    },
    {
      first: "Create a savings plan",
      second: "Select the amount you want to save, and for how long",
    },
    {
      first: "Review your savings plan",
      second:
        "Review your savings plan, see your interest rate, maturity date and confirm this",
    },
    {
      first: "Fund your plan and start saving",
      second:
        "Kickstart your savings plan by funding via: your Rex wallet, bank transfer or your card",
    },
  ];

  const dosomething = (id) => {
    setNo(id);

    if (id === 0) {
      setimage(one);
    } else if (id === 1) {
      setimage(two);
    } else if (id === 2) {
      setimage(three);
    } else {
      setimage(four);
    }
  };

  const data = {
    rows: [
      {
        title: "How can I create a saving plan?",
        content: `Create an account on Rex, click on  Savings; specify how much you want to save and for how long. Fund your 
        account to complete your savings plan.`,
      },
      {
        title: "What’s the interest rate for savings on Rex?",
        content:
          "The interest rate is calculated based on the tenure of your savings. Our interest rates are competitive. Earn up to 18% per annum.",
      },
      {
        title: "How does Rex help me achieve my financial goals? ",
        content: `Saving on Rex helps you develop the financial discipline needed to achieve your goals. Get started today and find out.`,
      },
    ],
  };

  const styles = {
    // bgColor: 'white',
    titleTextColor: "blue",
    rowTitleColor: "#7399EF",
    rowContentColor: "#121212",
    arrowColor: "#121212",
    rowTitleTextSize: "23px",
    rowContentPaddingTop: "20px",
    rowContentTextSize: "18px",
    rowContentPaddingBottom: "20px",
  };

  const config = {
    // animate: true,
    // arrowIcon: "V",
    tabFocus: true,
    openOnload: 1,
    expandIcon: "+",
    collapseIcon: "-",
  };
  return (
    <div className="savings">
      <div className="credit">
        <Header dark />
        <div className="home">
          <div className="body width">
            <div className="talk">
              <div className="cspi">
                {" "}
                <span>Savings </span>{" "}
              </div>
              <p>
                Earn more, Do More <br />
                with a <span> Rex Savings plan</span>
              </p>
              <p className="twentyone">
                Enjoy greater returns when you save on Rex. Saving is easier
                with our <br /> automated savings feature. Create a financial
                target, choose how frequently <br /> you want to save and start
                your journey to financial independence.
              </p>
              <p>Get started today</p>

              <div className="button">
                <img src={app2} alt="" />
                <img src={app} alt="" />
              </div>
            </div>
            <div className="img">
              <img src={phone1} alt="" />
            </div>
          </div>
        </div>

        <div className="yourmoney">
          <div className="width">
            <div className="put">
              <p></p>
              <p></p>
              <p className="twentyone"></p>
            </div>

            <div className="phonetext">
              <div className="phone">
                <img src={phone} alt="" />
              </div>
              <div className="text">
                <div className="save">
                  <p className="fifty">Save for the future</p>
                  <p className="twentyone">
                    Our products help you cultivate a good savings habit.
                  </p>
                </div>
                <div className="auto">
                  <div>
                    <img src={icon} alt="" />
                  </div>

                  <div className="talk">
                    <p>Save with a goal</p>
                    <p className="twentyone">
                      Save towards a goal on Rex. What your target is, you can
                      start putting away some money for it.That House Purchase,
                      Seed Capital, Dubai Vacation or Canadian Master’s Program
                      will not save for itself
                    </p>
                  </div>
                </div>
                <div className="auto">
                  <div>
                    <img src={icon2} alt="" />
                  </div>

                  <div className="talk">
                    <p>Save automatically</p>
                    <p className="twentyone">
                       Set a financial goal and enable autosave on Rex. With
                      this feature, you can save any amount and select how often
                      you want to be deducted (daily, weekly or monthly).
                    </p>
                  </div>
                </div>
                <div className="auto">
                  <div>
                    <img src={icon3} alt="" />
                  </div>

                  <div className="talk">
                    <p>Top up frequently</p>
                    <p className="twentyone">
                      Earn more interest by topping up on Rex. With this feature
                      you can top up your existing savings plan and grow your
                      money.  You can now maximize any extra income you earn
                      during the year by topping up.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="width">
          <div className="service">
            <p>How it works</p>
            <p>Save on Rex in 4 easy steps</p>
            <p>Start your journey to financial freedom today!</p>
          </div>
        </div>

        <div className="create">
          <div className="width">
            <div className="imagecreate">
              <div className="image">
                <img src={image} alt="" />
              </div>
              <div className="create">
                {details.map((detail, id) => (
                  <div
                    key={id}
                    onClick={() => dosomething(id)}
                    className={no === id ? "account active" : "account"}
                  >
                    <p>{detail.first}</p>
                    <p>{detail.second}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* faq */}
        <div className="frequently">
          <div className="width">
            <div>
              <div className="answer">
                <p>Frequently asked questions</p>
                <p>Let's answer some of your questions</p>
              </div>
              <Faq data={data} styles={styles} config={config} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
