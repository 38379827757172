import React from "react";
import Header from "../../components/header/Header";
import three from "../../image/1.png";
import two from "../../image/2.png";
import one from "../../image/3.png";

export default function Blog2() {
  return (
    <div className="Blog2">
      <div className="blog1">
        <div className="business">
          <Header />
          <div className="home">
            <div className="body width">
              <div className="future">
                <p>Rex launches its mobile app</p>
                <p>By Muyiwa Babarinde</p>
                <div className="socials">
                  <a href="https://web.facebook.com/rexafricaofficial">
                    <img src={two} alt="" />
                  </a>
                  <a href="https://twitter.com/rexafrica_">
                    <img src={one} alt="" />
                  </a>
                  <a href="https://www.linkedin.com/company/rexafrica/mycompany">
                    <img src={three} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="talk">
          <div className="width">
            <p>
              At Rex our goal is to democratize finance in Africa by giving you
              the tools to control your finances, either as an individual or a
              small business.
            </p>{" "}
            <br />
            <br />
            <p>
              I am glad to announce that we have just launched our mobile app.
              The app offers a range of financial services including credit,
              savings, investment, payments and buy now, pay later (BNPL)
              solutions.
            </p>{" "}
            <br />
            <br />
            <p>
              The launch of the mobile app marks a significant milestone for us,
              as we continue our mission to make financial services more
              accessible and affordable to millions of Africans. With the app,
              customers can now access a wide range of financial services from
              the comfort of their homes or offices, without the need to visit a
              physical branch.
            </p>{" "}
            <br />
            <br />
            <p>
              One of the key features of the app is the credit facility, which
              allows customers to access credit instantly, without the need for
              collateral or a lengthy application process. This is particularly
              important for small and medium-sized enterprises (SMEs) that may
              have difficulty accessing credit from traditional lenders.
            </p>{" "}
            <br />
            <br />
            <p>
              In addition to credit, the app also offers savings and investment
              products, allowing customers to save and invest their money for
              future use. The app also offers a range of payment solutions,
              including mobile money, card payments and bank transfers, making
              it easy for customers to pay bills, make purchases and transfer
              money to friends and family.
            </p>{" "}
            <br />
            <br />
            <p>
              One of the most exciting features of the app is the BNPL solution,
              which allows our customers to make purchases and pay for them over
              a period of time. This is particularly useful for customers who
              may not have the funds to pay for a large purchase upfront, but
              can afford to pay in installments over time.
            </p>
            <br />
            <br />
            <p>
              If you're interested in trying out the Rex mobile app, simply
              visit the Google Play Store or Apple App Store to download it
              today. With its user-friendly interface and intuitive design,
              you're sure to find it easy to use and navigate. So why wait?
              Download the app today and start enjoying the benefits of easy,
              affordable and accessible finance.
            </p>
            <br />
            <br />
            <p>
              <span>Muyiwa Babarinde,</span> <br />
              Head of Marketing & Growth.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
