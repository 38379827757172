import woman from "../../image/woman.png";
import Grow from "../../components/grow/Grow";
import Header from "../../components/header/Header";
import Someof from "../../components/Someof/Someof";
import Thecredit from "../../components/Thecredit/Thecredit";
import "./business.css";
export default function Business() {
  return (
    <div className="business">
      <Header />
      <div className="home">
        <div className="body width">
          <div className="talk">
            <div className="cspi"></div>
            <p>
              Get the financial <br />
              tools to{" "}
              <span>
                {" "}
                elevate your <br /> business
              </span>
            </p>
            <p className="twentyone">
              Businesses need all the help they can get to grow. Our goal is{" "}
              <br /> to provide you with the resources your business needs to
              get <br /> to the next level
            </p>
            <div className="button2">Get a free account</div>
          </div>
          <div className="img">
            <img src={woman} alt="" />
          </div>
        </div>
      </div>
      <Thecredit />
      <Someof />
      <Grow />
    </div>
  );
}
