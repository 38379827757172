import React from "react";
import Header from "../../components/header/Header";
import three from "../../image/1.png";
import two from "../../image/2.png";
import one from "../../image/3.png";
import first from "../../image/new/Screen Shot 2023-03-28 at 11.38 1 (1).png";
import second from "../../image/new/Screen Shot 2023-03-28 at 11.39 1 (1).png";
import yinka from "../../image/new/Screen Shot 2023-03-28 at 11.41 1.png";
import "./blog.css";

export default function Blog1() {
  return (
    <div className="blog1">
      <div className="business">
        <Header />
        <div className="home">
          <div className="body width">
            <div className="future">
              <p>Embedded Finance: The Future of Finance in Africa</p>
              <p>By Yinka Odeleye</p>
              <div className="socials">
                <a href="https://web.facebook.com/rexafricaofficial">
                  <img src={two} alt="" />
                </a>
                <a href="https://twitter.com/rexafrica_">
                  <img src={one} alt="" />
                </a>
                <a href="https://www.linkedin.com/company/rexafrica/mycompany">
                  <img src={three} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="talk">
        <div className="width">
          <p>
            I started my career in the 90’s, and back then there were a number
            of new generation banks including Guaranty Trust Bank and UBA that
            were trying to change the status quo, and usher a new level of
            accessibility to financial services for Nigerians.
          </p>{" "}
          <br />
          <br />
          <p>
            While there has been massive growth in the sector in the decades
            since then, the rise of digital technology over the past 10 years
            has had a profound impact on the finance industry globally, and
            Africa is no exception. 
          </p>{" "}
          <br />
          <br />
          <p>
            With the growth of digital apps, the proliferation of the internet,
            and the widespread adoption of smartphones, traditional banking
            models are being disrupted, paving the way for a new era of finance
            all over the continent, especially in Nigeria. From Personal
            Finance, to Credit, to Insurance, Business Banking et al.
          </p>{" "}
          <br />
          <br />
          <p>
            Bill Gates said in 1994 that ,
            <span> "We need banking, but we don't need banks anymore."</span>{" "}
            This statement is becoming increasingly relevant in today's digital
            age, as consumers look for new, more convenient and accessible ways
            to manage their finances. In Nigeria, this has led to the emergence
            of embedded finance, which is transforming the financial landscape.
          </p>{" "}
          <br />
          <br />
          <img src={first} alt="" />
          <p>
            {" "}
            Embedded finance refers to the integration of financial services
            into the everyday products and services that people use. This can
            include things like payment systems, insurance, loans, and other
            financial services, all seamlessly integrated into apps and websites
            that people already use. The result is a more convenient and
            accessible way of managing money, without having to navigate the
            traditional banking system.
          </p>{" "}
          <br />
          <br />
          <p>
            <span>The Widespread Adoption of Digital Products </span> <br /> The
            proliferation and rise of digital financial products in Nigeria like
            Paystack, Flutterwave, Chipper Cash, Cassava, Anchor etc  has played
            a key role in the rise of embedded finance. These apps are making it
            easier for people to manage their money, pay bills, and make
            transactions. The adoption of these apps has been driven by the
            growth of the internet and smartphone usage in Nigeria, which has
            made it possible for more people to access financial services on
            their mobile devices.
          </p>{" "}
          <br />
          <br />
          <img src={second} alt="" />
          <p>
            {" "}
            The Desire for Convenience in Finance:Another factor that has
            contributed to the rise of embedded finance in Nigeria is the
            growing demand for more convenient and accessible financial
            services. People want easy access to their finances, they are
            constantly on the lookout for ways to simplify their finances.
            Embedded finance offers a solution to this, with its seamless
            integration into the products and services people use every day.
          </p>{" "}
          <br />
          <br />
          <p>
            <span>Providing Access To All:  </span> <br /> The future is
            embedded finance. However, it is important to note that the
            transition to digital and alternative means of finance must be
            accelerated in order to reach critical mass. The recent policy of
            the Central Bank of Nigeria to redesign the naira notes is a
            testament to this. Despite the removal of over 2 trillion naira in
            old notes from circulation, the shortage of accessibility to the new
            notes has resulted in long queues at ATMs all over the country,
            causing inconvenience to consumers.
          </p>{" "}
          <br />
          <br />
          <p>
            This highlights the need for a faster pace of innovation in the
            financial sector, to ensure that the benefits of embedded finance
            are accessible to all. The successful adoption of digital financial
            products in Nigeria is crucial in order to keep up with the
            fast-paced changes in technology and the growing demand for
            convenient and accessible financial services.
          </p>{" "}
          <br />
          <br />
          <p>
            While the traditional banking model may be slowly fading/changing,
            it is important to remember that the financial sector still has a
            vital role to play in facilitating the transition to a more digital
            landscape. Banks can provide the infrastructure and resources that
            are necessary to support the growth of embedded finance and ensure
            that it is adopted in a responsible and sustainable manner.
          </p>{" "}
          <br />
          <br />
          <p>
            <span>Bringing It Together: </span> <br /> In conclusion, the future
            of finance in Africa, particularly in Nigeria, is embedded finance.
            However, the speed of the innovation and transition to this new
            model must be accelerated in order to reach critical mass.  
          </p>{" "}
          <br />
          <br />
          <p>
            The growth of digital technology, the proliferation of the internet,
            and the widespread adoption of smartphones has paved the way for
            this new era of finance, but we need to build on this foundation.  
          </p>{" "}
          <br />
          <br />
          <p>
            The recent policy of the Central Bank of Nigeria serves as a
            reminder of the challenges and obstacles that still need to be
            overcome in order to fully realize the potential of embedded finance
            in Africa. By working together, the financial sector and technology
            companies can drive this change, making financial services more
            convenient and accessible for consumers.
          </p>{" "}
          <br />
          <br />
        </div>
        <br />
        <br />
        <br />
        <div className="yinka">
          <div className="img">
            <img src={yinka} alt="" />
          </div>
          <div className="isa">
            Yinka Odeleye is a seasoned finance professional with over 3 decades
            of experience in Investment Banking, Corporate Finance, Commercial
            Banking, and Private Equity.  He is the founder of Rex Africa, a
            fintech startup that aims to democratize access to financial
            services for Nigerians.Prior to founding Rex Africa, Yinka worked at
            Synergy Capital Managers as a Partner, UCML Capital Limited as the
            Managing Director, Citi Bank as a Director in the Corporate Finance
            team, and Lehman Brothers as an Associate in Fixed Income Research.
            He has been instrumental in executing landmark deals in Nigeria and
            is passionate about fintech and financial services.
          </div>
        </div>
      </div>
    </div>
  );
}
