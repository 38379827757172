import "./getaccess.css";
import phone from "../../image/phone.png";

export default function Getaccess() {
  return (
    <div className="getaccess">
      <div className="width">
        <div className="imagetalk">
          <div className="image">
            <img src={phone} alt="" />
          </div>
          <div className="talk">
            <p className="fifty">
              Get access to <span> quick loans</span> <br /> to meet your
              financial <br />
              needs
            </p>
            <p className="eighteen">
              Are you financially stranded? Is your small business in need of a{" "}
              <br />
              financial boost? Rex provides you with the financing for <br />
              your personal and business needs. No collateral is needed. <br />
              Flexible repayment plan available. Be Rex assured. <br />
              Rex is here to provide the funds you need
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
