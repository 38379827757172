import React, { useState } from "react";
import "./cookie.css";
import { Link } from "react-router-dom";

const CookiePopup = () => {
  const [showPopup, setShowPopup] = useState(true);

  const acceptCookies = () => {
    setShowPopup(false);
    // Set a cookie or save the user's preference in local storage to remember the choice.
    // For this example, let's assume we save the choice in local storage.
    localStorage.setItem("cookiesAccepted", "true");
  };

  // Check if the user has accepted cookies previously.
  const cookiesAccepted = localStorage.getItem("cookiesAccepted");

  // If the user has already accepted cookies or closed the pop-up before, do not show it again.
  if (!showPopup || cookiesAccepted === "true") {
    return null;
  }

  return (
    <div className="cookie-popup">
      <div className="cookie-content">
        <p>
          By clicking this box, you agree to RexCredit Limited using your
          personal data in accordance with our Privacy Policy
        </p>
        <div className="buttons">
          <Link to="/privacy#cookie">Cookie Policy</Link>
          <button onClick={acceptCookies}>Accept</button>
        </div>
      </div>
    </div>
  );
};

export default CookiePopup;
