import "./getmore.css";
import copy from "../../image/mark.png";

export default function Getmore() {
  return (
    <div className="getmore">
      <div className="width">
        <div className="textphone">
          <div className="text">
            <p>Investment</p>
            <p>Get more from your money</p>
            <p className="eighteen">
              Earn great returns on your portfolio. Our investment products
              ensure you are protected from inflation and currency devaluation.
              Choose the investment package with the amount and duration closest
              to your needs and start earning daily interest on Rex.
            </p>
          </div>
          <div className="phone">
            <img src={copy} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
