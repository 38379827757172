import React, { useState } from "react";
import "./bnpl.css";
import Header from "../../components/header/Header";
// import pic from "../../image/bnpl.png";
import one from "../../image/logos/image 14.svg";
import two from "../../image/logos/image 15.svg";
import three from "../../image/logos/image 16.svg";
import four from "../../image/logos/image 17.svg";
import five from "../../image/logos/image 18.svg";
import six from "../../image/logos/image 19.svg";
import p1 from "../../image/s1.png";
import p2 from "../../image/s2.png";
import p3 from "../../image/s3.png";
import p4 from "../../image/s4.png";
import p5 from "../../image/s5.png";
import p6 from "../../image/s6.png";
import b1 from "../../image/b1.png";
import b2 from "../../image/b2.png";
import b3 from "../../image/b3.png";
import b4 from "../../image/b4.png";
import Faq from "react-faq-component";
import Lock from "../../components/Lock/Lock";
import app from "../../image/Group 33625.svg";
import app2 from "../../image/Group 33626.svg";

export default function Bnpl() {
  const details = [
    {
      first: "Download the app on your mobile device",
      second:
        "Create an account on Rex, provide all the necessary details and login",
    },
    {
      first: "Browse product list",
      second:
        "Look through the BNPL product page, check the specifications of the product you want and select how you want to spread your payment (daily, weekly or monthly)",
    },
    {
      first: "Make the 50% payment",
      second:
        "Fund your BNPL purchase with either: your Rex wallet, Bank Transfer or your added Card. Complete the transaction, and lock in your product for delivery on the date 50% of total cost is done      ",
    },
    {
      first: "Receive product",
      second:
        "Once you have paid 50% of the total value of your product, you will receive it, and you can start enjoying it.",
    },
  ];

  const [no, setNo] = useState(0);
  const [image, setimage] = useState(b1);

  const dosomething = (id) => {
    setNo(id);

    if (id === 0) {
      setimage(b1);
    } else if (id === 1) {
      setimage(b2);
    } else if (id === 2) {
      setimage(b3);
    } else {
      setimage(b4);
    }
  };

  const data = {
    rows: [
      {
        title: "How can I order an item and pay later on Rex?",
        content: `Browse through the BNPL section, select a product, pay 50% down payment and get your product delivered.`,
      },
      {
        title: "What is the interest rate on BNPL?",
        content:
          "Zero %. You save money by locking in today’s price, instead of buying at tomorrow’s higher price.",
      },
      {
        title: "What products can I get with Rex’s BNPL option?",
        content: `Electronics, household appliances, phones are all available on Rex.`,
      },
    ],
  };

  const styles = {
    // bgColor: 'white',
    titleTextColor: "blue",
    rowTitleColor: "#7399EF",
    rowContentColor: "#121212",
    arrowColor: "#121212",
    rowTitleTextSize: "23px",
    rowContentPaddingTop: "20px",
    rowContentTextSize: "18px",
    rowContentPaddingBottom: "20px",
  };

  const config = {
    // animate: true,
    // arrowIcon: "V",
    tabFocus: true,
    openOnload: 1,
    expandIcon: "+",
    collapseIcon: "-",
  };
  return (
    <div className="bnpl">
      <div className="credit">
        <div className="business">
          <Header />
        </div>

        <div className="home">
          <div className="body width">
            <div className="talk">
              <div className="cspi">
                <span> Buy now pay later</span>
              </div>

              <p>
                Buy Now, Pay Later <br /> enjoy
                <span> 0% interest</span>
              </p>
              <p className="twentyone">
                Rex is a Neobank that provides financial services to
                <br /> individuals and small businesses in Africa. We give you
                {""}
                <br /> the tools to control your finances. Get a free bank
                account,{""}
                <br /> save, invest and get quick loans on Rex.
              </p>
              <p>Get started today</p>

              <div className="button">
                <img src={app2} alt="" />
                <img src={app} alt="" />
              </div>
            </div>
          </div>
        </div>

        <Lock />

        <div className="someof">
          <div className="width">
            <p>Brands you can buy and pay later on Rex</p>
            <div className="img">
              <div className="image">
                <img src={one} alt="" />
              </div>
              <div className="image">
                <img src={two} alt="" />
              </div>
              <div className="image">
                <img src={three} alt="" />
              </div>
              <div className="image">
                <img src={four} alt="" />
              </div>
              <div className="image">
                <img src={five} alt="" />
              </div>
              <div className="image">
                <img src={six} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="yourfav">
          <div className="width">
            <div className="yourmoney">
              <div className="put">
                <p></p>
                <p>Some brand items</p>
                <p className="twentyone">
                  Choose your most urgent household need from the list below,
                  pay 50% now and <br /> spread the remaining payments.
                </p>
              </div>
            </div>{" "}
            <div className="image g">
              <div className="gal">
                <img src={p1} alt="" />
              </div>
              <div className="gal">
                <img src={p2} alt="" />
              </div>
              <div className="gal">
                <img src={p3} alt="" />
              </div>
            </div>
            <div className="image">
              <div className="gal">
                <img src={p4} alt="" />
              </div>
              <div className="gal">
                <img src={p5} alt="" />
              </div>
              <div className="gal">
                <img src={p6} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="width">
          <div className="service">
            <p></p>
            <p>BNPL in 4 quick and easy steps</p>
            <p>
              Reduce the strain on your cash flow by buying now and paying
              later.
            </p>
          </div>
        </div>

        <div className="create">
          <div className="width">
            <div className="imagecreate">
              <div className="image">
                <img src={image} alt="" />
              </div>
              <div className="create">
                {details.map((detail, id) => (
                  <div
                    key={id}
                    onClick={() => dosomething(id)}
                    className={no === id ? "account active" : "account"}
                  >
                    <p>{detail.first}</p>
                    <p>{detail.second}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* faq */}
        <div className="frequently">
          <div className="width">
            <div>
              <div className="answer">
                <p>Frequently asked questions</p>
                <p>Let's answer some of your questions</p>
              </div>
              <Faq data={data} styles={styles} config={config} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
