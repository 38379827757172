import "./control.css";
import hand from "../../image/hand.png";
// import line from "../Group 33613.png";

export default function Control() {
  return (
    <div className="control">
      <div className="width">
        <div className="pictalk">
          <div className="pic">
            <img src={hand} alt="" />
          </div>
          <div className="talk">
            <p>Cards</p>
            <p className="fifty">
              Take charge of your finances with Rex Debit Card
            </p>
            <p className="eighteen">
              Pay your bills, withdraw, transfer funds, and make purchases with
              the Rex debit card.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
