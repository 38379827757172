import React, { useState } from "react";
import "./header.css";
import rex from "../../image/Frame.svg";
import rex2 from "../../image/Frame2.png";
import menu from "../../image/menu.svg";
import menub from "../../image/menub.svg";
import close from "../../image/close-circle.svg";
import icon from "../../image/payment.png";
import icon2 from "../../image/credit.png";
import icon3 from "../../image/fixed.png";
import icon4 from "../../image/savings.png";
import icon6 from "../../image/buy.png";
import { Link } from "react-router-dom";

export default function Header({ dark }) {
  const [iscontent1, setcontent1] = useState(false);
  const [iscontent2, setcontent2] = useState(false);
  const [isActive, setActive] = useState(false);
  // const [isActive2, setActive2] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };

  const opencontent = () => {
    setcontent1(!iscontent1);
  };

  const opencontent2 = () => {
    setcontent2(!iscontent2);
  };

  return (
    <div
      className="header "
      style={dark ? { background: "#002766" } : { background: "white" }}
    >
      <div className="width">
        <div className="logo">
          <Link to="/">
            <img src={dark ? rex : rex2} alt="" />
          </Link>
        </div>
        <div className="text">
          <ul>
            <li>
              For Individuals
              <div className="sub-menu">
                <div className="width">
                  <div className="content">
                    <div className="three">
                      <div className="spend">
                        <Link to="/payment">
                          <div className="image">
                            <img src={icon} alt="" />
                          </div>
                          <div className="talks">
                            <p>Payments</p>
                            <p></p>
                          </div>
                        </Link>
                      </div>
                      <div className="spend">
                        <Link to="/credit">
                          <div className="image">
                            <img src={icon2} alt="" />
                          </div>
                          <div className="talks">
                            <p>Credit</p>
                            <p></p>
                          </div>
                        </Link>
                      </div>

                      <div className="spend">
                        <Link to="/bnpl">
                          <div className="image">
                            <img src={icon6} alt="" />
                          </div>
                          <div className="talks">
                            <p>Buy now pay later</p>
                            <p></p>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="three">
                      <div className="spend">
                        <Link to="/savings">
                          <div className="image">
                            <img src={icon4} alt="" />
                          </div>
                          <div className="talks">
                            <p>Savings{"  "}</p>
                            <p></p>
                          </div>
                        </Link>
                      </div>

                      <div className="spend">
                        <Link to="/fixed">
                          <div className="image">
                            <img src={icon3} alt="" />
                          </div>
                          <div className="talks">
                            <p>Fixed deposit</p>
                            <p></p>
                          </div>
                        </Link>
                      </div>

                      <div className="spend  hide">
                        <div className="image">
                          <img src={icon} alt="" />
                        </div>
                        <div className="talks">
                          <p>Cards</p>
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <Link to="/forbusiness"> For Business</Link>
            </li>

            <li>
              {/* <Link to="/faq"> */}
              FAQs
              {/* </Link> */}
            </li>
            <li>
              {/* <Link to="/blogmain"> */}
              Blog
              {/* </Link> */}
            </li>
          </ul>
        </div>

        <div className="menu" onClick={handleToggle}>
          <div className="img">
            <img src={dark ? menu : menub} alt="" />
          </div>
        </div>
      </div>

      <div className={isActive ? "active" : "mobile"}>
        <div className="three">
          <div onClick={handleToggle} className="logo">
            <img src={rex} alt="" />
          </div>

          <div className="menu" onClick={handleToggle}>
            <img src={close} alt="" />
          </div>
        </div>

        <div className="products" onClick={opencontent}>
          For Individuals
        </div>
        <div className={iscontent1 ? "content" : "noncon"}>
          <div className="for">
            <Link to="/payment">
              <div className="image">
                <img src={icon} alt="" />
              </div>
              <div className="oro">
                <p>Payments</p>
                <p></p>
              </div>
            </Link>
          </div>
          <div className="for">
            <Link to="/credit">
              <div className="image">
                <img src={icon2} alt="" />
              </div>
              <div className="oro">
                <p>Credit</p>
                <p></p>
              </div>
            </Link>
          </div>
          <div className="for">
            <Link to="/fixed">
              <div className="image">
                <img src={icon3} alt="" />
              </div>
              <div className="oro">
                <p>Fixed deposit</p>
                <p></p>
              </div>
            </Link>
          </div>
          {/* <div className="for">
            <div className="image">
              <img src={icon4} alt="" />
            </div>
            <div className="oro">
              <p>Cards</p>
              <p></p>
            </div>
          </div>
          <div className="for">
            <div className="image">
              <img src={icon5} alt="" />
            </div>
            <div className="oro">
              <p>Spend</p>
              <p></p>
            </div>
          </div> */}
          <div className="for">
            <Link to="/savings">
              <div className="image">
                <img src={icon4} alt="" />
              </div>
              <div className="oro">
                <p>Savings</p>
                <p></p>
              </div>
            </Link>
          </div>
          <div className="for">
            <Link to="/bnpl">
              <div className="image">
                <img src={icon6} alt="" />
              </div>
              <div className="oro">
                <p>Buy now pay later</p>
                <p></p>
              </div>
            </Link>
          </div>
        </div>

        <div className="products">
          <Link to="/forbusiness">For Business</Link>
        </div>

        <div className="products">Faq</div>

        <div className="products" onClick={opencontent2}>
          Blog
        </div>
      </div>
    </div>
  );
}
